<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto" />
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div v-if="!verficatonSuccessful" class="p-8">
                <div class="vx-card__title mb-8 w-full">
                  <tp-cardtext v-bind:min-height="165">
                    <p>Email verification link has expired or invalid. Please request a new confirmation email in account page.</p>
                  </tp-cardtext>
                  <vs-button
                    type="border"
                    to="/account"
                    class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-6"
                  >Account</vs-button>
                </div>
              </div>
              <div v-if="verficatonSuccessful" class="p-8">
                <div class="vx-card__title mb-8 w-full">
                  <h4 class="mb-4">Email Has Been Confirmed</h4>
                  <tp-cardtext v-bind:min-height="150">
                    <p>Your email has been confirmed.</p>
                  </tp-cardtext>
                  <vs-button
                    type="border"
                    to="/"
                    class="float-right px-4 w-full md:w-auto mt-3 mb-8 md:mt-0 md:mb-6"
                  >Go to home page</vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import TpCardtext from "../../components/travio-pro/TpCardtext";
import { Form } from '@/core-modules/form-framework/Form.js'

export default {
  components: {
    TpCardtext,
  },
  data() {
    return {
      form: new Form({
        token: null
      }),
      verficatonSuccessful: false
    };
  },
  created() {
    this.form.token = this.$route.params.token;
    this.form.post('api/accounts/generalSettings/verifyEmail')
      .then(response => {
        this.verficatonSuccessful = true;
      })
  }
};
</script>

<style>
</style>