<template>
  <div class="container px-0" v-bind:style="{ minHeight: minHeight + 'px' }">
    <div v-bind:class="{center: centerXy, verticalCenter: !centerXy }">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'tp-cardtext',
  props: {
    minHeight: {
      type: Number,
      default: 0
    },
    centerXy: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {

    }
  },
}
</script>

<style>
.container { 
  position: relative;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.vertical-center {
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

</style>